import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const PlansAPI = {

    async getAllPlans() {
        let pld = (await axios.get(`${API_ENDPOINT}/plans/all`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },


    async createPlan(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/plan`, data)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async updatePlan(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/plan`, data)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async deletePlan(id) {
        let pld = (await axios.post(`${API_ENDPOINT}/plan/delete`, {id: id})).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },


}

export default PlansAPI;

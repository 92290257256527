import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../helpers/CommonHelper";

import * as usersActions from '../../redux/actions/UsersActions'

export default function CardioMoodTemplate(props) {
    let {active} = props;
    const dispatch = useDispatch();
    let {currentUser, loading, isAdmin} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = (currentUser != undefined && currentUser.userRole == 'admin');
        return {
            currentUser: currentUser,
            loading: state.users.loading,
            isAdmin: isAdmin
        }
    }, []));

    let namItems = (isAdmin == true) ? ADMIN_NAV_ITEMS : NAV_ITEMS;

    return (
        <Wrapper>
            <Left>
                <LeftTopPlaceholder>
                    <LogoPlaceholder>
                        <LogoImage className={'heart'} src={require('../../assets/images/cLogo.png')}/>
                        <LogoLabel>
                            SubtitlesLove
                        </LogoLabel>
                    </LogoPlaceholder>
                    <AvatarPlaceholder>
                        <Avatar
                            src={currentUser.avatar == undefined ? require('../../assets/images/man_d.svg') : currentUser.avatar}/>
                    </AvatarPlaceholder>
                    <UserNamePlaceholder>
                        {CommonHelper.getUserName(currentUser)}
                    </UserNamePlaceholder>

                </LeftTopPlaceholder>
                <LeftBottomPlaceholder>
                    <NavPlaceholder>
                        {namItems.map((a, j) => {
                            return (
                                <NavItem selected={(active == a.name)} key={a.name} onClick={() => {
                                    CommonHelper.linkTo(a.url);
                                }}>
                                    <NavLogo src={a.icon}/>
                                    <NavLabel>
                                        {a.label}
                                    </NavLabel>
                                </NavItem>
                            )
                        })}
                    </NavPlaceholder>
                    <LogoutPlaceholder>
                        <NavItem onClick={async () => {
                            await dispatch(usersActions.logOut());
                            window.location.reload();
                        }}>
                            <NavLogo src={require('../../assets/images/logout_image.svg')}/>
                            <NavLabel>
                                Logout
                            </NavLabel>
                        </NavItem>
                    </LogoutPlaceholder>
                </LeftBottomPlaceholder>
            </Left>
            <Right>
                {props.children}
            </Right>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: stretch;
`;

const Left = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    background: #1A1A56;
    width: 220px;
`;

const Right = styled.div`
    flex: 1;
    overflow-y: auto;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
`;

const LeftTopPlaceholder = styled.div`
    min-height: 200px;
    color: white;
`;

const LogoPlaceholder = styled.div`
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LogoImage = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 7px;
`;

const LogoLabel = styled.div`
    color: white;
    opacity: 0.5;
    font-size: 24px;
`;

const LogoutPlaceholder = styled.div`
    padding-bottom: 20px;
`;

const LeftBottomPlaceholder = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    background: #252367;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    padding-top: 20px;
    box-sizing: border-box;
`;

const NavPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const NavItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    height: 50px;
    padding-left: 35px;
    opacity: ${props => (props.selected == true ? 1 : 0.3)};
    :hover{
      opacity: 0.8;
    }
`;

const NavLogo = styled.img`
    height: 24px;
    width: 24px;
    margin-right: 10px;
`;

const NavLabel = styled.div`
    color: white;
`;

const AvatarPlaceholder = styled.div`
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const CurrentHeartRatePlaceholder = styled.div`
    
`;

const avaSize = 65;

const Avatar = styled.div`
    width: ${avaSize}px;
    height: ${avaSize}px;
    border-radius: 1000px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => props.src});
    border: 1px solid white;
`;

const UserNamePlaceholder = styled.div`
    text-align: center;
    color: white;
    padding: 5px;
    box-sizing: border-box;
    opacity: 0.67;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const NAV_ITEMS = [
    {
        label: 'Home',
        name: 'index',
        url: '/',
        icon: require('../../assets/images/home-icon-silhouette.svg')
    },
    {
        label: 'Groups',
        name: 'groups',
        url: '/groups',
        icon: require('../../assets/images/group-d.svg')
    },
    // {
    //     label: 'Realtime',
    //     name: 'realtime',
    //     url: '/realtime',
    //     icon: require('../../assets/images/heart_real.svg')
    // },
    // {
    //     label: 'Billing',
    //     name: 'billing',
    //     url: '/billing',
    //     icon: require('../../assets/images/bill.svg')
    // },
    {
        label: 'Settings',
        name: 'settings',
        url: '/settings',
        icon: require('../../assets/images/cogwheels.svg')
    },
];

const ADMIN_NAV_ITEMS = [
    {
        label: 'Home',
        name: 'index',
        url: '/',
        icon: require('../../assets/images/home-icon-silhouette.svg')
    },
    {
        label: 'Users',
        name: 'users',
        url: '/users',
        icon: require('../../assets/images/group-d.svg')
    },
    {
        label: 'Subscriptions',
        name: 'subscriptions',
        url: '/subscriptions',
        icon: require('../../assets/images/subscription_white.svg')
    },
    {
        label: 'Stats',
        name: 'stats',
        url: '/stats',
        icon: require('../../assets/images/bar-chart.svg')
    },
];

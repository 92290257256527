import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

export default function MobileHelpApp() {

    return (
        <Wrapper>

            <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Magna sit amet purus gravida. Adipiscing elit duis tristique sollicitudin nibh sit
                amet commodo nulla. Viverra nibh cras pulvinar mattis. Vitae aliquet nec ullamcorper sit amet. Semper
                quis lectus nulla at volutpat diam ut venenatis tellus. Mauris vitae ultricies leo integer malesuada
                nunc vel. Volutpat ac tincidunt vitae semper quis lectus. Lorem donec massa sapien faucibus et molestie
                ac feugiat. Tortor at risus viverra adipiscing at in. Diam in arcu cursus euismod quis.
            </P>

            <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Magna sit amet purus gravida. Adipiscing elit duis tristique sollicitudin nibh sit
                amet commodo nulla. Viverra nibh cras pulvinar mattis. Vitae aliquet nec ullamcorper sit amet. Semper
                quis lectus nulla at volutpat diam ut venenatis tellus. Mauris vitae ultricies leo integer malesuada
                nunc vel. Volutpat ac tincidunt vitae semper quis lectus. Lorem donec massa sapien faucibus et molestie
                ac feugiat. Tortor at risus viverra adipiscing at in. Diam in arcu cursus euismod quis.
            </P>

            <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Magna sit amet purus gravida. Adipiscing elit duis tristique sollicitudin nibh sit
                amet commodo nulla. Viverra nibh cras pulvinar mattis. Vitae aliquet nec ullamcorper sit amet. Semper
                quis lectus nulla at volutpat diam ut venenatis tellus. Mauris vitae ultricies leo integer malesuada
                nunc vel. Volutpat ac tincidunt vitae semper quis lectus. Lorem donec massa sapien faucibus et molestie
                ac feugiat. Tortor at risus viverra adipiscing at in. Diam in arcu cursus euismod quis.
            </P>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100vw;
    box-sizing: border-box;
    padding: 5px;
`;

const P = styled.p`
    font-size: 16px;
`;

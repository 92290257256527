import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import useComponentSize from '@rehooks/component-size'

// mode = fit / zoom
export default function FittedVideoPanel(props) {
    const {
        background = 'pink', ratio = 1.7777,
        videoSrc
    } = props;

    const ref = useRef(null);
    let size = useComponentSize(ref);
    let {width, height} = size;

    let containerRatio = (width == undefined) ? 1 : (1.0 * width / height);
    let videoWidth = width, videoHeight = height;

    if (containerRatio > ratio) { // wider
        videoWidth = width;
        videoHeight = 1.0 * width / ratio
    } else { // narrower
        videoHeight = height;
        videoWidth = 1.0 * height * ratio;
    }

    console.log('render: size = ', size);

    return (
        <Wrapper ref={ref} background={background}>
            <VideoPlaceholder width={videoWidth} height={videoHeight}>
                <Video controls={false} autoPlay={true} loop={true} muted={true}>

                    <source src={videoSrc}/>

                </Video>
            </VideoPlaceholder>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => props.background};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    
`;

const VideoPlaceholder = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background: whitesmoke;
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
`;

import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/PlansActions";
import {Button} from "../../ui/Button";
import UpdateSubscriptionForm from "../forms/UpdateSubscriptionForm";

export default function AdminAllSubscriptionsPanel(props) {
    const [selectedId, setSelectedId] = useState(undefined);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const {loading, plans, isAdmin} = useMappedState(useCallback(state => {
        return {
            loading: state.users.loading || state.plans.loading,
            plans: state.plans.plansMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId)
        }
    }, []));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadAllPlans());
    }, []);
    let selectedPlan = (selectedId == undefined) ? undefined : plans.filter(d => (d.id == selectedId))[0];
    if (plans.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>

            <TopButtonPlaceholder>
                <Button onClick={() => {
                    setAddModalVisible(true);
                }}>
                    Add subscription plan
                </Button>
            </TopButtonPlaceholder>

            <ItemsList>
                {plans.map((p, k) => {
                    return (
                        <Item selected={(selectedId == p.id)} key={p.id} onClick={() => {
                            setSelectedId(p.id);
                        }}>
                            <TopItemContent>
                                <ItemTopInfo>
                                    <Name>
                                        {`${p.label} (${p.name}, ${p.paddle_id})`}
                                    </Name>
                                    <Description>
                                        {p.description}
                                    </Description>
                                </ItemTopInfo>
                                <ItemPrice>
                                    <div style={{fontWeight: 'normal', opacity: 0.3, fontSize: 14}} >
                                        {`${p.videos_number} video${+p.videos_number > 1 ? `s` : ``}`}
                                    </div>
                                    <div>
                                        {`$${p.price}`}
                                    </div>
                                </ItemPrice>
                            </TopItemContent>
                        </Item>
                    )
                })}
            </ItemsList>

            <Sidebar width={Math.min(720, window.innerWidth)} visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>
                {addModalVisible == false ? null :
                    <div>
                        <UpdateSubscriptionForm
                            loading={loading}
                            onSave={async d => {
                                await dispatch(actions.createPlan(d));
                                setAddModalVisible(false);
                            }}/>
                    </div>
                }
            </Sidebar>

            <Sidebar width={Math.min(720, window.innerWidth)} visible={(selectedPlan != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>
                {selectedPlan == undefined ? null :
                    <div>
                        <UpdateSubscriptionForm
                            {...selectedPlan}
                            loading={loading}
                            onSave={async d => {
                                await dispatch(actions.updatePlan({...d, id: selectedPlan.id}));
                                setSelectedId(undefined);
                            }}/>
                    </div>
                }
            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ItemsList = styled.div`
    
`;

const Item = styled.div`
    margin-bottom: 10px;
    padding: 10px;
    background: white;
    border-radius: 4px;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 18px;
`;

const ItemTopInfo = styled.div`
    flex: 1;
`;

const ItemPrice = styled.div`
    width: 120px;
    text-align: right;
    font-weight: bold;
    font-size: 20px;
`;

const TopItemContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Description = styled.div`
    
`;

const TopButtonPlaceholder = styled.div`
    margin-bottom: 20px;
`;

